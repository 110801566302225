@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('bootstrap/dist/css/bootstrap.min.css');

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

/* font-family: "Rajdhani", sans-serif; */




.nam_bann {
    background: url('../images/namb_bann.png') no-repeat center;
    background-size: cover;
    height: 100vh;
}
.nam_bann_inner {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.nam_bann_inner h2 {
    font-size: 99px;
    font-weight: 700;
    margin: 0;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    font-family: "Rajdhani", sans-serif;
}
.nam_bann_inner p {
    font-size: 48px;    
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
    font-family: "Rajdhani", sans-serif;       
}
.socialIcWrp {
     display: flex;
     display: -webkit-flex;
     justify-content: center;
     padding-top: 202px;
}



@media (max-width: 599px) {
    .nam_bann_inner h2 {
        font-size: 50px;
    }
    .nam_bann_inner p {
        font-size: 30px;
    }
    .socialIcWrp {       
        padding-top: 80px;
    }
}

* {margin:0; padding:0; outline:0; -webkit-box-sizing:border-box; box-sizing:border-box; list-style:none;}
body {font-size:15px; line-height:normal; color: #000; font-family: 'Poppins', sans-serif; background: #ffff;}
a {outline:none; cursor: pointer; text-decoration:none; color:#000; -webkit-transition:ease-in-out .3s; -moz-transition:ease-in-out .3s; -ms-transition:ease-in-out .3s; -o-transition:ease-in-out .3s; transition:ease-in-out .3s;}
a:hover {text-decoration:none;}
input, textarea, select {font-size:14px; color:#000; vertical-align:middle; font-family: 'Poppins', sans-serif; outline:none; resize:none;}
input[type="button"], input[type="submit"], button {-webkit-transition:ease-in-out .3s; -moz-transition:ease-in-out .3s; -ms-transition:ease-in-out .3s; -o-transition:ease-in-out .3s; transition:ease-in-out .3s; -moz-appearance:none; -webkit-appearance:none; appearance:none; border: none; cursor: pointer; font-family: 'Poppins', sans-serif;}
select {appearance: none; -ms-appearance: none; -webkit-appearance: none;}
select::-ms-expand {display: none;}
.transition {-webkit-transition:ease-in-out .5s; -moz-transition:ease-in-out .5s; -ms-transition:ease-in-out .5s; -o-transition:ease-in-out .5s; transition:ease-in-out .5s;}
img {border:none; vertical-align:top; max-width:100%;}
.memberPortal img{width: 160px;
  border-radius: 4px;height: 50px;}
.tac {text-align:center;}
.tal {text-align:left;}
.tar {text-align:right;} 
.vAlign {vertical-align:middle;}
ul {padding: 0; margin: 0; list-style: none;}
.mainCon {width:100%; position:relative; min-height:100vh; padding: 70px 0 0 0;}
.container {max-width:1200px; width: 100%; margin:auto; padding:0 15px;}
.flexBox {display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; -ms-flex-wrap: wrap; flex-wrap: wrap;}
.flexBox.nowrap {flex-wrap: nowrap;}
.itemCenter {-webkit-align-items: center; -ms-align-items: center; align-items: center;}
.spacebetween {justify-content: space-between; -webkit-justify-content: space-between;}
.material-icons {font-family: 'Material Icons'; font-weight: normal; font-style: normal; font-size: 24px; display: inline-block; line-height: 1; text-transform: none; letter-spacing: normal; word-wrap: normal; white-space: nowrap; direction: ltr; -webkit-font-smoothing: antialiased; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale; font-feature-settings: 'liga';}

.header {background: #fff; height: 70px; width: 100%; box-shadow: 0 0 10px rgba(0,0,0,.1); position: fixed; left: 0; top: 0; z-index:101;}
.logo {position: absolute; left: 50%; top: 5px; margin-left: -71px;}
.logo img {transition: ease-in-out .3s;}
.header.sticky .logo img {transform: scale(.6); margin-top: -25px;}
a.showMobMenu {display: none;}
.headerInner {height: 70px;}
.menu ul li {font-size: 15px; text-transform: uppercase; font-weight: 600; margin-right: 20px;}
.menu ul li:last-child {margin-right: 0;}
.menu ul li a {color: #000; display: flex; height: 70px; align-items: center; position: relative;}
.menu ul li a:hover, .menu ul li a.active {color: #014d9b;}
.menu ul li a.active:after {content: ''; width: 100%; height: 4px; background: #014d9b; position: absolute; left: 0; bottom: 0;}
.socialLinks li {padding: 0 8px;}
.topButtons li {margin-left: 10px;}
.topButtons li a {display: block; height: 42px; line-height: 40px; color: #014d9b; border: 2px solid #014d9b; padding: 0 10px; font-size: 16px; text-transform: uppercase; font-weight: 600; width: 100px; text-align: center;}
.topButtons li a:hover {background: #1db5ed; color: #fff; border-color: #1db5ed;}


.topButtons .linkInner {margin-left: 10px;}
.topButtons .linkInner a {display: block; height: 42px; line-height: 40px; color: #014d9b; border: 2px solid #014d9b; padding: 0 10px; font-size: 15px; text-transform: uppercase; font-weight: 600; min-width: 100px; text-align: center; border-radius: 4px;}
.topButtons .linkInner a:hover {background: #1db5ed; color: #fff; border-color: #1db5ed;border-radius: 4px;}

.bannerCon {background-size: cover !important;}
.bannerCon .container {height: 550px; align-items: flex-end; padding-bottom: 50px;}
.bannerInfo {width: 100%; text-align: center; color: #fff; text-transform: uppercase; text-shadow: 0 0 10px #000;}
.bannerWht {font-size: 56px; font-weight: 700; width: 100%; line-height: 56px;}
.bannerBlue {font-size: 46px; font-weight: 500; width: 100%;}
.bannerText {font-size: 15px; padding: 10px 0 40px 0; color: rgba(255,255,255,.8); line-height: 24px;}
a.bannerBtn {border-radius: 4px;display: inline-block; background: #ed1d24; font-size: 16px; color: #fff; text-transform: uppercase; font-weight: 600; padding: 0 35px; height: 42px; line-height: 42px;border-radius: 4px;}
a.bannerBtn:hover {background: #014d9b;border-radius: 4px;}


.sliderCon {margin-top:0; padding: 20px 0; background: #e9e9e9; border-top: 2px solid #fff; }
.sliderCon .container {display: flex; flex-wrap: wrap; justify-content: space-between;}
.upcomingSlider {width: 48%; position: relative; justify-content: space-between;}
.upcomingSlider .item {overflow: hidden; background-color:#fff !important; border: 5px solid #ccc; padding: 20px;}
.UEDet {width: 50%; position: relative; padding: 0 20px 0 0; font-size: 16px; color: rgba(0,0,0,.8); line-height:26px;}
.UEDet .inner {position: relative; z-index: 10;}
.UEDet span {display: block; color: #000; font-weight: 600; line-height: normal; font-size: 26px; text-transform: uppercase; padding-bottom: 5px;}
.UEImage {width: 50%; height: auto; display: flex; align-items: center; justify-content: center;  overflow: hidden; position: relative; background-size: cover !important;}
.UEImage:after {content: ''; display: none; width: 100%; height: 100%; position: absolute; left:0; top:0; background: -moz-linear-gradient(top,  rgba(0,0,0,0.06) 15%, rgba(0,0,0,0.06) 17%, rgba(0,0,0,0.87) 100%); background: -webkit-linear-gradient(top,  rgba(0,0,0,0.06) 15%,rgba(0,0,0,0.06) 17%,rgba(0,0,0,0.87) 100%); background: linear-gradient(to bottom,  rgba(0,0,0,0.06) 15%,rgba(0,0,0,0.06) 17%,rgba(0,0,0,0.87) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f000000', endColorstr='#de000000',GradientType=0 );}

.UEImage img {max-height: 100%; max-width: 100%;}
.UEImage .date {font-size: 16px; color: #fff; font-weight: 600; text-transform: uppercase; position: absolute; left: 0; top: 0; z-index: 10; padding: 20px 30px;}
.UEImage .text {font-size: 22px; color: #fff; font-weight: 600; text-transform: uppercase; position: absolute; left: 0; bottom: 0; z-index: 10; padding: 20px 30px;}
.UEImage .text span {display: block; font-size: 32px;}

.upcomingSlider .slick-dots {position: absolute; left: 50%; bottom: 5px; margin-left: -585px; display: flex !important;}
.upcomingSlider .slick-dots li {margin-right: 10px;}
.upcomingSlider .slick-dots li button {border: 2px solid #01a4ef; width: 28px; height: 28px; color: #fff; text-align: center; font-size: 14px; line-height: 24px; background: none; opacity: .4;}
.upcomingSlider .slick-dots li:hover button, .upcomingSlider .slick-dots li.slick-active button {opacity: 1;}

.upcomingSlider .slick-arrow {position: absolute; top: 22px; background: url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/wht_arrow.png); width: 35px; height: 19px; border: none; overflow: hidden; text-indent: -500px; z-index: 11;}
.upcomingSlider .slick-arrow.slick-prev {background-position: 0 0; right: 60px;}
.upcomingSlider .slick-arrow.slick-prev:hover {width: 45px;}
.upcomingSlider .slick-arrow.slick-next {background-position: right 0; right: 10px;}
.upcomingSlider .slick-arrow.slick-next:hover {width: 45px;}

.pt50 {padding-top: 20px;}
.pb50 {padding-bottom: 20px;}
.whtBg {background: #fff;}
.hdBox {margin-bottom: 20px;}
.mainHd {font-size: 38px; font-weight: 600; text-transform: uppercase; line-height: 34px; text-align: right;}
.mainHd span {display: block;}
.hdText {border-left: 1px solid #000; margin-left: 20px; padding:5px 0 5px 20px; font-size: 15px; color: rgba(0,0,0,.8); font-weight: 500; line-height: 24px;}
.comEventBox {margin: -10px;}
.comEventBox li {padding: 10px; width: 50%;}
.comEventBox li .inner {position: relative; height: 100%; height: 290px;}
.comEventBox li .inner .lft {width: 185px; position: relative; background: url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/completed_event_bg.jpg); background-size: cover;flex:auto}
.comEventHd {color: #fff; font-size: 34px; text-transform: uppercase; font-weight: 600; padding:25px; width: 270px; line-height: 32px; position: absolute; left: 0; top: 0;}
.comEventHd span {display: block; font-size: 14px; font-weight: 400; line-height: normal; padding-top: 5px;}
.comEventBtn {position: absolute; bottom: 0; left: 0; padding:25px;}
.comEventBtn a {display: inline-block; background: #fff; font-size: 18px; color: #000; font-weight: 600; text-transform: uppercase; width: 180px; text-align: center; padding: 0 20px; border-radius: 4px; white-space: nowrap; height: 56px; line-height: 56px;}
.comEventBtn a:hover {background: #014d9b; color: #fff;}
.comEventBox li .inner .rgt {width: calc(100% - 185px); background-size: cover !important;}

.newsSlider .item {padding: 0 10px;}
.newsSlider .item .inner {position: relative; background: #000;}
.newsSlider .item .inner .img img {width: 100%;height:400px;}
.newsSlider .slick-list {margin: 0 -10px;}
.newsSlider .item .over {position: absolute; left: 0; top:0; width: 100%; height:400px; background: rgba(0,0,0,.7); padding: 20px; color: #fff; display: flex; flex-direction: column; justify-content: space-between; opacity: 0;}
.newsSlider .item .over .hd {font-weight: 600; text-transform: uppercase; font-size: 26px; line-height: 28px;}
.newsSlider .item .over .hd span {display: block; line-height: normal; font-size: 17px; padding-top: 5px;}
.newsSlider .item .over .readMore a {font-size: 17px; font-weight: 600; text-transform: uppercase; color: #fff; position: relative; display: inline-block; padding-right: 40px;}
.newsSlider .item .over .readMore a:after {content: ''; background: url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/wht_arrow.png) no-repeat right center; position: absolute; right: 0; top: 2px;  width: 35px; height: 19px; transform: scale(.8); transition: ease-in-out .5s;}
.newsSlider .item .over .readMore a:hover {padding-right: 50px;}
.newsSlider .item:hover .over {opacity: 1;}

.newsSlider .slick-arrow {position: absolute; top: -45px; overflow: hidden; text-indent: -500px; z-index: 11; width: 40px; height: 38px; border: 2px solid #014d9b;}
.newsSlider .slick-arrow:after {content: ''; background: url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/blue_arrow.png); width: 20px; height: 10px; position: absolute; top: 12px;}
.newsSlider .slick-arrow.slick-prev {right:50px;}
.newsSlider .slick-arrow.slick-prev:after {left: 8px; background-position: 0 0;}
.newsSlider .slick-arrow.slick-next {right: 0;}
.newsSlider .slick-arrow.slick-next:after {left: 8px; background-position: right 0;}

.grayBg {background: #f5f5f5;}
.clientList {margin: -10px;}
.clientList li {padding: 10px; width: 25%;}
.clientList li img {width: 100%;}

.footer {background: #01458b;}
.footerTop {margin: 0 -10px;}
.footerInfo {width: 280px; padding: 45px 10px;}
.footerBot {background: #00081b; font-size: 15px; color: #fff; text-transform: uppercase; padding:20px 15px;}
.footerText {color: #fff; font-size: 15px; line-height: 22px; padding: 15px 0 0 0;}
.quickLinks {width: 350px; padding: 45px 10px;}
.footerHd {font-size: 20px; color: #fff; font-weight: 600; text-transform: uppercase; padding-bottom: 15px;}
.footerLinks {width: 48%;}
.footerLinks li {font-size: 16px; text-transform: uppercase; padding: 5px 0;}
.footerLinks li a {display: inline-block; position: relative; color: #fff; padding-left: 12px;}
.footerLinks li a:before {content: ''; width: 4px; height: 4px; border-radius: 100%; background: #fff; position: absolute; left: 0; top: 10px;}
.footerLinks li a:hover {color: #0095fd;}
.footerLinks li a:hover:before {background: #0095fd;}
.contactInfo {width: 320px; padding: 45px 10px;}
.contactInfo .info {font-size: 17px; color: #fff; margin-bottom: 25px; padding-left: 40px; position: relative;}
.contactInfo .info:last-child {margin-bottom: 0;}
.contactInfo .info a {color: #fff;}
.contactInfo .info a:hover {color: #0095fd;}
.contactInfo .info .icon {width: 28px; height: 28px; font-size: 28px; position: absolute; left: 0;}
.contactInfo .info.location .icon {top: 2px;}
.contactInfo .info.phone .icon {top: 1px;}
.contactInfo .info.mail .icon {top: 0;}
.socialMedia {padding: 45px 10px;}
.footerSM li {padding: 0 5px 0 0;}
.footerSM li:last-child {padding-right: 0;}
.footerSM li a {display: flex; width: 32px; height: 32px; border-radius: 2px; align-items: center; justify-content: center;}
.footerSM li a:hover {background: #0095fd}

.innerBanner {position: relative; background-size: cover !important;}
.innerBanner:before {content: ''; width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: rgba(0,0,0,.6);}
.innerBanner .container {position: relative; z-index: 10; height: 270px; align-items: flex-end; padding: 25px 15px;}
.innerHd {font-size: 36px; color: #fff; text-transform: uppercase; font-weight: 600; padding-bottom: 5px; width: 100%;}
.bradcrumb {display: inline-block; background: rgba(255,255,255,.5); border-radius: 4px; padding: 6px 10px; color: #fff; font-size: 14px; font-weight: 500;}
.bradcrumb a {display: inline-block; position: relative; margin-right: 5px; color: #fff;}
.bradcrumb a:after {content: '/'; margin-left: 10px;}
.contentBox {color: #282828; font-size: 15px; line-height: 25px; font-family: 'Poppins', sans-serif;}

.whtBox {background: #fff; border-radius: 0 0 4px 4px; box-shadow: 0 0 10px rgba(0,0,0,.2);}
.eventTab {border-bottom: 1px solid #cecfcf; padding: 20px 30px 0 30px;}
.eventTab li {font-size: 19px; font-weight: 600; font-family: 'Poppins', sans-serif; margin-right: 30px;}
.eventTab li:last-child {margin-right: 0;}
.eventTab li a {display: block; color: #000; padding-bottom: 10px; position: relative;}
.eventTab li a:hover,
.eventTab li a.active {color: #014d9b;}
.eventTab li a.active:after {content: ''; width: 100%; position: absolute; left: 0; bottom: 0; height: 5px; background: #01458b;}
.eventDetCon {padding: 0 30px 30px 30px;}
.districtSelectBox {padding: 20px 0; font-size: 15px; font-weight: 600;}
.districtSelectBox select {width: 350px; background: url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/select_arrow.png) no-repeat right 10px center; border: 1px solid #b0b0b0; border-radius: 6px; color: #014d9b; font-size: 17px; padding: 8px 12px; font-weight: 600; margin-left: 10px;}

.eventList {font-family: 'Poppins', sans-serif;}
.eventList li {font-size: 15px; margin-bottom: 20px;}
.eventList li .top {font-weight: 600;}
.eventList li .top > div {width: 30%; padding: 6px 20px;}
.eventList li .top > div:first-child {width: 40%;}
.eventList li .top > div.closed {font-size: 13px; padding: 10px 20px;}
.eventList li .bot > div {width:30%; padding: 15px 20px;}
.eventList li .bot > div:first-child {width: 40%;}
.eventList li .bot > div .entries {font-weight: 600; color: #014d9b;}
.eventList li .bot .location {font-weight: 600;}

.clubLft {width: 60%; padding: 0 30px 30px 30px; border-right: 1px solid #cecfcf;}
.clubList li {border-top: 1px solid #e5e5e5; font-family: 'Poppins', sans-serif;}
.clubList li a {display: flex; width: 100%; justify-content: space-between; padding: 12px; font-size: 15px; font-weight: 600; align-items: center;}
.clubList li a span {font-size: 13px; font-weight: 400; white-space: nowrap; padding-left: 10px; color: #000;}
.clubList li a:hover, .clubList li.active a {background: #f5f5f5; color: #0095fd;}
.clubRgt {padding: 30px; width: 40%; font-family: 'Poppins', sans-serif;}
.clubHd {font-size: 20px; font-weight: 600; padding: 10px 0 0 0;}
.clubDet {padding: 5px 0 0 0;}
.clubDet li {margin-bottom: 20px; font-size: 13px; line-height: 22px;}
.clubDet li:last-child {margin-bottom: 0;}
.clubDet li span {display: block; font-weight: 600;}
.clubDet li a {color: #0095fd;}

.raceLft {width: 40%; padding: 0 15px 30px 15px; border-right: 1px solid #cecfcf;}
.raceRgt {width: 60%; font-family: 'Poppins', sans-serif;}
.rrTop {padding: 20px 20px 0 20px;}
.rrTop .hd {font-size: 18px; font-weight: 600; padding: 0 15px 0 0;}
.rrTop .hd span {display: block; font-size: 14px; font-weight: 400;}
.printBtn {display: inline-block; border: 2px solid #1c60a6; color: #1c60a6; text-transform: uppercase; font-size: 13px; font-weight: 600; padding: 5px 12px; white-space: nowrap;}
.printBtn span {display: inline-block; vertical-align: middle; margin-right: 5px;}
.printBtn:hover {background: #01458b; color: #fff;}
.rrTbalehd {font-size: 16px; font-weight: 600; padding: 20px 20px 0 20px;}
.rrTable {width: 100%; overflow: auto; border-bottom: 1px solid #cecfcf;}
.rrTable table {width: 100%; font-size: 13px; font-weight: 600;}
.rrTable table th {font-weight: 600; border-top: 1px solid #cecfcf; border-bottom: 1px solid #cecfcf; padding: 6px 20px;}
.rrTable table td {padding: 6px 20px; white-space: nowrap;}
.blueClr {color: #01458b;}


.dbMainCon {width:100%; position:relative; background: #ebedf5;}
a.showDBMenu {display: none;}
.dbLeft {width: 150px; height: 100%; background: #01458b; position: fixed; left: 0; top: 0; display: flex; flex-direction: column; justify-content: space-between; overflow: auto; z-index: 100;}
.dbLogo {width: 100%; text-align: center; padding: 10px;}
.dbMenu {text-align: center;}
.dbMenu ul li {font-size: 14px; font-weight: 500; padding: 15px;}
.dbMenu ul li a {color: #fff; display: inline-block;}
.dbMenu ul li a .icon {width: 60px; height: 60px; border-radius: 4px; margin: 0 auto 5px auto; display: flex; align-items: center; justify-content: center; background: rgba(0,149,253,.2);}
.dbMenu ul li a .icon span {font-size: 36px; width: 36px; height: 36px;}
.dbMenu ul li.active a .icon, .dbMenu ul li a:hover .icon, .dbMenu ul li a.active .icon {background: rgba(0,149,253,1);}

.userImg {width: 100%; text-align: center; padding:20px 10px;}
.userImg img {width: 60px; border-radius: 100%;}

.myRace {width: 450px; height: 100%; overflow: auto; background: #fff; position: fixed; right: 0; top: 0;}
.showRace {display: none;}
.userName {padding: 30px; font-size: 22px; font-weight: 600; font-family: 'Poppins', sans-serif;}
.userName img {width: 50px; border-radius: 100%; margin-right: 10px;}
.raceListBox {padding: 0 30px;}
.myRaceHd {font-size: 25px; font-weight: 600; font-family: 'Poppins', sans-serif;}
.raceList {padding-bottom: 10px;}
.raceList li {margin-top: 15px; font-family: 'Poppins', sans-serif;}
.raceList li a {background: #ebedf5; border-radius: 6px; padding:15px 20px; width: 100%; position: relative;}
.raceList li a .date {font-size: 16px; font-weight: 600; white-space: nowrap; padding-right:20px;}
.raceList li a .date span {display: block;}
.raceList li a .name {font-size: 20px; font-weight: 600; padding-right: 20px; line-height: 26px;}
.raceList li a .icon {position: absolute; right: 10px;}
.raceList li a:hover {background: #b6c4fc;}

.dbRight {padding: 0 0 0 150px;}
.dbMid {padding: 30px; height: 100vh; overflow: auto; margin-right: 450px;}
.searchBox {width: 350px; background: #fff; border-radius:6px; overflow: hidden; margin-bottom: 25px;}
.searchInput {padding: 10px; border: none; background: none; font-size: 18px; font-weight: 600; width: 100%;}
.searchBtn {padding: 5px 10px; background: none; border: none; color: #b2b2b2; font-weight: 600; margin-top: 2px;}
.searchBtn span {font-size: 28px; width: 28px; height: 28px;}
.topLink {margin-bottom: 25px;}
.topLink li {margin-left: 15px;}
.topLink li a {background: #dadbe3; width: 42px; height: 42px; border-radius: 8px; display: flex; color: #6d6d71; align-items: center; justify-content: center;}
.topLink li a:hover {background: #01458b; color: #fff;}

.dbMainHd {font-size: 30px; font-weight: 600; padding-bottom: 10px;}
.dbTop {margin: -25px;}
.dbTop li {width: 33.33%; padding: 25px;}
.dbTop li .inner {background: #ccc; border-radius: 8px; padding: 30px; height: 100%; box-shadow: 0 0 15px rgba(0,0,0,.2); color: #fff; font-size: 19px; font-weight: 600; position: relative;}
.dbTop li .inner span {display: block; font-size: 50px;}
.dbTop li .inner .icon {position: absolute; right: 20px; top: 50%; margin-top: -35px;}
.mb30 {margin-bottom: 30px;}
.uocomingRaces li {margin-bottom: 8px; background: #fff; border-radius: 8px; box-shadow: 0 0 10px rgba(0,0,0,.2); padding: 20px 30px; display: flex; align-items: center; justify-content: space-between;}
.uocomingRaces li .left {display: flex; align-items: center;}
.uocomingRaces li .icon {min-width: 70px;}
.uocomingRaces li .icon img {border-radius: 100%; height: 70px; width: 70px;}
.uocomingRaces li .det {padding: 0 25px; font-size: 14px; font-weight: 600; font-family: 'Poppins', sans-serif;}
.uocomingRaces li .det .hd {font-size: 20px; font-weight: 600; display: block; padding-bottom: 5px;}
.uocomingRaces li .det .hd span {display: inline-block; color: #db402c;}
.uocomingRaces li .det .hd span.blue {color: #525de0;}
.uocomingRaces li .det .hd a:hover {color: #01458b;}
.uocomingRaces li .rgt {display: flex;}
.uocomingRaces li .rgt a {color: #01458b; font-size: 20px; text-transform: uppercase; font-weight: 600; border: 3px solid #0095fd; white-space: nowrap; padding: 8px 25px; border-radius: 30px; margin-left: 5px;}

.dbMid.dbInner {margin: 0 !important; overflow: visible; height: auto;}
.profileDet {margin: -15px;}
.profileDet > li {width: 50%; padding: 15px;}
.profileDet > li.full {width: 100%;}
.profileDet li .inner {background: #fff; height: 100%; border-radius: 6px; padding: 30px; box-shadow: 0 0 10px rgba(0,0,0,.2);}
.userInfo .left {width: 160px; padding-top: 5px; position: relative;}
.userInfo .left img {width: 100%;}
.userInfo .right {width: calc(100% - 160px); padding: 0 0 0 30px; font-size: 18px; font-weight: 600; line-height: 26px; position: relative;}
.userInfo .right a.editBtn {display: block; height: 40px; line-height: 38px; color: #014d9b; border: 2px solid #014d9b; padding: 0 10px; font-size: 16px; text-transform: uppercase; font-weight: 600; width: 80px; text-align: center; position: absolute; right: 0; top: 0;}
.userInfo .right a.editBtn:hover {background: #1db5ed; color: #fff; border-color: #1db5ed;}

.editLink {position: absolute; display: flex; align-items: center; justify-content: center; width:32px; height: 32px; background: #00abeb; right: 0; bottom: 0; color: #fff; cursor: pointer;}

.profileHd {font-size:26px; font-weight: 600; padding-bottom: 10px;}
.clubMemebrList {width: 100%;}
.clubMemebrList li {display: flex; width: 100%; align-items: center; justify-content: space-between; font-size: 18px; font-weight: 600; padding-bottom: 5px;}
.clubMemebrList li .left {color: #00abeb;}
.raceAttended {width: 100%;}
.raceAttended li {display: flex; width: 100%; align-items: center; justify-content: space-between; font-size: 14px; font-weight: 600; padding-bottom: 5px;}
.raceAttended li .left {color: #00abeb; font-size: 20px; padding-right: 20px;}
.raceAttended li .right {text-align: right;}

.settingForm {max-width: 800px; margin: -10px;}
.settingForm li {padding: 10px;}
.formInput {width: 100%; padding: 12px 20px; border-radius: 6px; font-size: 17px; border: 1px solid #b0b0b0; font-weight: 500;}
.formSelect {width: 100%; padding: 12px 20px; border-radius: 6px; font-size: 17px; border: 1px solid #b0b0b0; font-weight: 500; background: #fff url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/select_arrow.png) no-repeat right 10px center;}
.formBtn {background: #00abeb; font-size: 16px; font-weight: 600; text-transform: uppercase; padding: 12px 30px; color: #fff; min-width: 170px; text-align: center; border: none;}
.formBtn:hover {background: #01458b;}
.formBtn[disabled] {
  /* Styles for the disabled button */
  // background-color: #a0a0a0; /* Light gray */
  cursor: not-allowed;
  /* You can customize other styles as needed */
}
.raceDetail {width: 450px; height: 100%; overflow: auto; background: #fff; position: fixed; right: 0; top: 0; padding: 30px 30px 60px 30px; font-family: 'Poppins', sans-serif;}
.raceDetImg {position: relative;}
.raceDetImg img {width: 100%; border-radius: 10px;}
.raceDetImg .icon {width: 55px; height: 55px; background: #fff; position: absolute; left:10px; bottom: 10px;}
.raceDetImg .icon img {border-radius: 0;}
.raceInfo {padding: 10px 0; font-size: 15px; font-weight: 600;}
.raceInfo .hd {display: block; font-size: 20px; font-weight: 600; padding-bottom: 5px;}
.raceInfo .hd span {display: block; color: #db402c; font-size: 16px;}
.raceDet {border-top: 1px solid #d4d4d4; padding: 10px 0;}
.raceHd {display: block; font-size: 16px; font-weight: 600; padding-bottom: 2px;}
.locationText {font-size: 15px; font-weight: 600;}
.locationText a {display: inline-block; color: #0095fd; margin-top: 5px;}
.locationText a:hover {color: #000;}
.locationText .sep {margin: 0 8px; color: #0095fd;}
.raceText {font-size: 13px; font-weight: 600; color: #3b3b3b;}
.hotelList {margin: -10px; padding: 5px 0;}
.hotelList li {padding: 10px;}
a.viewMoreDet { cursor: pointer ;width: 450px; position: fixed; right: 0; bottom: 0; background: #0095fd; font-size: 20px; color: #fff; text-transform: uppercase; font-weight: 600; padding: 12px; text-align: center; font-family: 'Rajdhani', sans-serif;}
a.viewMoreDet:hover {cursor: pointer;background: #000;}
.raceMid {padding: 30px; height: 100vh; overflow: auto; margin-right: 450px;}
.hideRaces {display: none;}
.resultMidLeft {padding: 30px; width: 54%; height: 100vh; overflow: auto;}
.resultMidRight {padding: 30px; width: 46%; height: 100vh; overflow: auto; background: #fff; font-family: 'Poppins', sans-serif;}
.resultList li {margin-bottom: 10px; font-family: 'Poppins', sans-serif;}
.resultList li a {background: #fff; display: block; border-radius: 6px; padding:15px 70px 15px 20px; width: 100%; position: relative; box-shadow: 0 0 10px rgba(0,0,0,.2);}
.resultList li a .name {font-size: 20px; font-weight: 600; line-height: 26px; padding-bottom: 5px; display: block;}
.resultList li a .date {font-size: 16px; font-weight: 600; display: block;}
.resultList li a .icon {position: absolute; right: 10px; color: #666; top: 50%; margin-top: -12px;}
.resultList li a:hover {background: #eee;}
.resultHd {font-size: 27px; font-weight: 600; border-bottom: 1px solid #cccccc; padding-bottom: 10px;}
.hideResult {display: none;}
.resultTop {padding: 20px 0 0 0;}
.resultTop .hd {font-size: 20px; font-weight: 600; padding: 0 15px 0 0; color: #0095fd;}
.resultTop .hd span {display: block; font-size: 14px; font-weight: 600; color: #000; padding-top: 5px;}

.rsultTbalehd {font-size: 18px; font-weight: 600; padding: 20px 0 5px 0;}
.rsultTable {width: 100%; overflow: auto; border-bottom: 1px solid #cecfcf;}
.rsultTable table {width: 100%; font-size: 15px; font-weight: 600;}
.rsultTable table th {font-weight: 600; border-top: 1px solid #cecfcf; border-bottom: 1px solid #cecfcf; padding: 6px 5px;}
.rsultTable table td {padding: 6px 5px; white-space: nowrap; font-weight: 500;}
.rsultTable table td.blueClr {color: #0095fd;}

.memberList {padding-bottom: 65px;}
.memberList li {margin-top: 10px; font-family: 'Poppins', sans-serif;}
.memberList li a {background: #ebedf5; border-radius: 6px; padding:15px 20px; width: 100%;}
.memberList li a .name {font-size: 17px; font-weight: 600; padding-right: 20px; line-height: 22px;}
.memberList li a .count {font-size: 14px; font-weight: 600; border: 2px solid #0095fd; border-radius: 16px; padding: 3px 12px;}
.memberList li a:hover {background: #b6c4fc;}
.userName.member {padding: 10px;}

.myClubList li {margin-bottom: 8px; background: #fff; border-radius: 8px; box-shadow: 0 0 10px rgba(0,0,0,.2); padding: 20px 30px; display: flex; align-items: center; justify-content: space-between; font-family: 'Poppins', sans-serif;}
.myClubList li .left {padding-right: 20px;}
.myClubList li .left a {display: flex; align-items: center; color: #0095fd; font-size: 18px; font-weight: 600;}
.myClubList li .left a img {min-width: 70px; width: 70px; border-radius: 100%; margin-right: 15px;}

.myClubList li .rgt {display: flex; align-items: center;}
.myClubList li .rgt .district {font-size: 13px; color: #696969; font-weight: 500; border: 1px solid #696969; width: 100px; height: 30px; border-radius: 30px; display: flex; justify-content: center; align-items: center; margin-right: 20px;}
.myClubList li .rgt .name {width: 150px; color: #282828; font-weight: 500; margin-right: 20px;}
.myClubList li .rgt .name span {display: block; font-weight: 600;}
.myClubList li .rgt .btn { background: #018b42; font-size: 13px; color: #fff; font-weight: 500; border: 1px solid #018b42; width: 100px; height: 30px; border-radius: 30px; display: flex; justify-content: center; align-items: center; margin-right: 20px;}
.myClubList li .rgt .viewDet {white-space: nowrap;}
.myClubList li .rgt .viewDet a {color: #616161; font-size: 13px; font-weight: 600; display: flex; align-items: center;
}
.myClubList li .rgt .viewDet a span {margin-left: 5px;}
.myClubList li .rgt .viewDet a:hover {color: #01458b;}

.memebrBtnBox {width: 450px; position: fixed; right: 0; bottom: 0; background: #0095fd; font-family: 'Poppins', sans-serif; display: flex;}
.memebrBtnBox a {font-size: 16px; color: #fff; font-weight: 600; padding: 5px; width: 50%; display: flex; align-items: center; justify-content: center; text-align: center; border-right: 1px solid #fff;}
.memebrBtnBox a:hover {background: #000;}

.topHdBox .left {width: 60%; padding-right: 40px; margin-bottom: 15px;}
.topHdBox .left .dbMainHd {padding-bottom: 5px;}
.topHdBox .left .text {font-size: 18px; font-weight:600;}
.topHdBox .left .text .blueClr {color: #0095fd;}
.topHdBox .right {width: 40%; margin-bottom: 15px;}
.topHdBox .right .inner {padding: 2px 0; font-size: 18px; font-weight: 500; text-align: right;}
.topHdBox .right .inner span {font-weight: 600;}
.topHdBox .score {width: 100%; padding-right: 40px; margin-bottom: 15px;}

.dbWhtBox {background: #fff; border-radius: 8px; box-shadow: 0 0 10px rgba(0,0,0,.2); padding:30px;}
.dbSubHd {font-size: 22px; font-weight: 600; padding-bottom: 10px;}
.labelText {font-size: 18px; font-weight: 600; margin-right: 20px;}
.labelText.active {color: #01a4ef;}
.membershipForm {max-width: 800px; margin: -10px -15px; padding-bottom: 25px;}
.membershipForm li {width: 50%; padding: 10px 15px;}
.fieldHd {display: block; font-size: 15px; font-weight: 600; padding-bottom: 5px;}
.dateBox {margin: -5px; display: flex;}
.dateBox .inner {width: 100%; padding: 5px;}
.emailNot {display: flex; height: 100%; align-items: flex-end; font-size: 15px; font-weight: 600; padding-bottom: 10px;}
.checkWrapper {position:relative; width:24px; height:24px; display:inline-block; vertical-align:middle; margin:0 5px 4px 0;}
.checkWrapper .checkInpt {opacity:0; height:24px; width:24px; position:absolute; top:0; left:0; z-index:2; margin:0;}
.checkWrapper .checkInpt + .bg {height:24px; width:24px; display:inline-block; position:absolute; top:0; left:0; z-index:1; border:2px solid #0095fd; background:#fff; border-radius: 3px;}
.checkWrapper .checkInpt:checked + .bg {background:#0095fd url(https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/check_icon.png) no-repeat center;}

.checkInpt {opacity:1; height:24px; width:24px; margin-right: 5px;}

.radioWrapper {position:relative; width:20px; height:20px; display:inline-block; vertical-align:middle; margin:0 5px 4px 0;}
.radioWrapper .radioInpt {opacity:0; height:20px; width:20px; position:absolute; top:0; left:0; z-index:2; margin:0;}
.radioWrapper .radioInpt + .bg {height:20px; width:20px; display:inline-block; position:absolute; top:0; left:0; z-index:1; border:2px solid #999; background:#fff; border-radius: 100%;}
.radioWrapper .radioInpt:checked + .bg {background:#fff; border: 2px solid #0095fd; position: relative;}
.radioWrapper .radioInpt:checked + .bg:after {content: ''; width: 8px; height: 8px; background: #0095fd; position: absolute; left: 4px; top: 4px; border-radius: 100%;}

.radioWrapper .radioInpt {height:20px; width:20px; margin-right: 5px;}

.topHdBox .left.ecTop {width: 100%; padding-right: 0; }
.editForm {max-width: 1100px; margin: -10px -15px; padding-bottom: 25px;}
.editForm li {width: 100%; padding: 10px 15px;}
.editForm li.full {width: 100%;}
.pr {position: relative;}
.dateIcon {position: absolute; right: 10px; top: 50%; margin-top: -12px; color: #999;}
.phoneBox {display: flex;}
.phoneBox .codeSelect {width: 100px; min-width: 100px; margin-right: 10px;}
.formInput.smallInput {min-width: 120px; width: 120px;}
.profileInfo {margin-left: 10px; align-items: flex-start; position: relative; color: #0095fd; font-size: 15px; font-weight: 600;}
.profileInfo .hd {display: block; color: #000000;}
.profileInfo .hd .icon {color: #0095fd; margin: 0 2px 4px 0; vertical-align: middle;}
.tcText {padding: 10px 0 0 0; font-size: 12px; font-weight: 600;}
.itemTop {align-items: flex-start;}

.blueBtn {border-radius: 4px; display: inline-block; background: #fff; border: 2px solid #1c60a6; color: #1c60a6; text-transform: uppercase; font-size: 16px; font-weight: 600; padding: 8px 15px; white-space: nowrap;}
.blueBtn:hover {background: #01458b; color: #fff;border-radius: 4px; }

.dbTable {width: 100%; overflow: auto;}
.dbTable table {width: 100%; font-family: 'Poppins', sans-serif;}
.dbTable table th {font-size: 14px; font-weight: 600; text-align: left; border-bottom: 1px solid #cdced8; padding: 0 10px 10px 0; white-space: nowrap;}
.dbTable table td {border-bottom: 1px solid #cdced8; padding: 10px 10px 10px 0; vertical-align: top;}
.dbTable table td .hd {font-size: 17px; font-weight: 600; color: #01a4ef; white-space: nowrap;}
.dbTable table td .hd span {display: block; font-size: 13px; font-style: italic;}
.actionBox a {display: inline-block; margin-right: 10px; color: #01a4ef; font-weight: 600;}
.actionBox a:hover {color: #000;}
.actionBox a:last-child {margin-right: 0;}

.summarylist {min-width: 200px;}
.summarylist li {font-size: 14px; padding: 3px 0; color: #282828; display: flex;}
.summarylist li span {width: 120px; min-width: 120px; font-weight: 500;}
.dbTable table td .status {display: block; width: 100px; height: 36px; color: #fff; font-size: 15px; font-weight: 600; text-align: center; line-height: 36px;}
.dbTable table td .status.active {background: #01a4ef;}
.dbTable table td .status.inactive {background: #fc0000;}
.reqLink {font-size: 14px; width: 150px;}
.reqLink a {color: #01a4ef;}


.pagination {display: flex; justify-content: center; padding: 20px 0 0 0;
  li {margin: 0 5px;
    a {display: inline-block; border: 1px solid #ccc; padding: 6px 12px;
      &:hover {background: #014d9b; color: #fff; border-color: #014d9b;}
    }
    &.active {
      a {background: #014d9b; color: #fff; border-color: #014d9b;}
    }
  }
}


  .errorMsg{    
    color: red;
    display: block;
    padding: 10px 0 0 0;
}

.memberPortal {margin-bottom: 25px;}
.memberPortal button {background: #0095fd; border-radius: 4px; padding: 10px 30px; color: #fff; font-size: 18px; font-weight: 600;}

.dbAdminLeft {width: 200px; height: 100%; background: #01458b; position: fixed; left: 0; top: 0; overflow: auto; z-index: 100;}
.dbAdminMenu {
  li {font-size: 16px; padding: 0 0 10px 20px;
    a {display: block; padding: 12px 10px 12px 25px; font-weight: 500; color: #fff; border-radius: 30px 0 0 30px;
      &:hover {background: #fff; color: #01458b;}
      &.active {background: #fff; color: #01458b; font-size: 19px;}
    }
  }
}
.dbAdminRight {padding: 0 0 0 200px;}

.modal-backdrop {background:rgba(0,0,0,.1) !important;}

#heatOptions .swal-inputsbutton {text-align: left; width: 390px; margin: auto;}
#heatOptions .swal-inputsbutton input {position: static; display: inline; margin: 0 5px 2px 0;}
.swal-modal .swal-footer {text-align: center;}
.rght {
  flex:0 0 40%;
  padding: 10px;
}
.lft{
  flex: 0 0 60%;
  padding: 10px
}
.centerStyle {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '100vh';
};

.sendingEmailStyle{
  background: '#f0f0f0';
  padding: '20px';
  border-radius: '4px';
  box-shadow: '0 2px 5px rgba(0, 0, 0, 0.1)';
};
/* Add this CSS in your style file or style tag */
@keyframes pulse {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.loading-dots {
  display: inline-block;
  animation: pulse 1.5s infinite;
}

.smllInput #transponder-dropdown {padding:7px 10px;}
.smllInput #transponder-dropdown-label {line-height: normal; transform: translate(14px, 9px) scale(1);}

.transTable {border: 1px solid #eee; width: 100%;}
.transTable th {background: #eee; padding: 10px;}
.transTable td {background: #fff; padding: 10px; border-top: 1px solid #eee;}
.transTable td input {border: 1px solid #ccc; padding:6px 10px; width:170px;} 
.transTable td .actBtn button {background: #01458b; font-size: 13px; border-radius:4px; color: #fff; padding: 5px 10px;}
.transTable td .actBtn button.cancel {background: #999; margin-left: 5px;}
.addTransponder {font-size: 18px; padding: 20px 0 10px 0;}

.MuiDialog-paper {width: 100% !important; margin: 0 !important;}
.transId {position: relative;}
.transId p {position: absolute; left: 0; top: 100%; margin-top: 4px;}

.listLeft {padding-right: 10px;}
.listLeft .formSelect {padding: 7px 25px 7px  10px; border-radius: 4px; font-size: 15px;}
.userinfo {border: 1px solid #eee;}
.userinfo th {background: #f6f6f6; padding: 10px; width:33.33%}
.userinfo td {background: #fff; border-top: 1px solid #eee; padding: 10px !important; width:33.33%}
.form1 .formSelect {padding: 4px 25px 6px 10px; border-radius: 4px; font-size: 15px;width: 70%;}

.editSubHd {font-size: 20px; font-weight: 600; border-bottom: 1px solid #ccc; padding-bottom: 8px; margin-bottom:15px;}
.editForm {display: flex; flex-wrap: wrap;}
.editForm li.half {width: 50%;}
.dobBox {display: flex; justify-content: space-between;}
.dobBox .formSelect {width: 32%;}
.phoneBox {position: relative; padding-left: 140px;}
.phoneBox .formSelect {width: 150px; position: absolute; left: 0; top: 0; height: 100%;}
.optBox {margin: -10px; padding-top: 10px;}
.optBox label {padding: 10px;}



